<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span v-if="!loading"
        >Muokkaa lomaketta <span v-if="currentForm">{{ currentForm.name }}</span></span
      >
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <v-card class="mt-2">
      <!-- Loader -->
      <full-page-loader
        v-if="loading"
        text="Ladataan lomaketta..."
        class="full-page-loader"
      ></full-page-loader>

      <!-- Form -->
      <form-builder v-if="!loading" :edit="true"></form-builder>
    </v-card>
  </div>
</template>

<script>
import FormBuilder from "./FormBuilder.vue";
import mixins from "@/mixins/mixins";
import FullPageLoader from "@/components/FullPageLoader";
import { mapActions, mapMutations, mapState } from "vuex";
import { loadFont } from "../../utils/helpers";

export default {
  title: "Muokkaa lomaketta",
  mixins: [mixins],

  computed: {
    ...mapState("form", ["loading", "currentForm"]),
  },

  components: {
    FormBuilder,
    FullPageLoader,
  },

  created() {
    this.setLoading(true);
    this.getOne(this.$route.params.id);
  },

  mounted() {
    this.$nextTick(() => {
      loadFont(this.currentForm?.styles?.fontFamily);
    });
  },

  watch: {
    loading(val) {
      if (!val && this.currentForm?.styles?.fontFamily) {
        loadFont(this.currentForm?.styles?.fontFamily);
      }
    },
  },

  methods: {
    ...mapActions("form", ["getOne"]),
    ...mapMutations("form", ["setLoading"]),
  },
};
</script>

<style scoped></style>
